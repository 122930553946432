import React from 'react';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import FAQItem from '../../components/FAQ-item';
import Tabs from '../../components/tabs';

import {tabs} from '../../helpers/constants/index';

import './style.scss';

const HttpStatusCodes: React.FC = (): JSX.Element => {
  return (
    <Layout>
      <PageHero
        title="HTTP status codes"
        subtitle={
          <p>
            The <b style={{fontWeight: 600}}>IANA</b> (Internet Assigned Numbers Authority) maintains the official
            registry of <b style={{fontWeight: 600}}>HTTP</b> (Hypertext Transfer Protocol) status codes.
          </p>
        }
      />
      <div className="httpStatusCodes-tabs">
        <Tabs dataTabs={tabs} />
      </div>
      <Section>
        <div className="col-12 d-block mb_20" style={{maxWidth: '1200px', width: '100%'}}>
          <p>
            Microsoft <b>IIS</b> (Internet Information Services) sometimes uses additional decimal sub-codes for more
            specific information, however, these sub-codes only appear in the response payload and in the documentation,
            not in the place of an actual HTTP status code.
          </p>
          All HTTP response status codes are separated into five classes (or categories). The first digit of the
          status-code defines the class of response, the last two digits do not have any class or categorization role.
          <p></p>
          <FAQItem
            title="1XX - Informational "
            text={
              <>
                <p>
                  An informational response indicates that the request was received and understood. It is issued on a
                  provisional basis while request processing continues.
                </p>
                <ul>
                  <li>
                    <span>
                      <b>100</b> Continue{' '}
                      <i>
                        This interim response indicates that everything so far is OK and that the client should continue
                        with the request or ignore it if it is already finished.
                      </i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>101</b> Switching Protocols{' '}
                      <i>
                        This code is sent in response to an Upgrade request header by the client and indicates the
                        protocol the server is switching to.
                      </i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>102</b> Processing (WebDAV){' '}
                      <i>
                        This code indicates that the server has received and is processing the request, but no response
                        is available yet.
                      </i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>103</b> Early Hints{' '}
                      <i>
                        This status code is primarily intended to be used with the
                        <a
                          href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Link"
                          target="_blank"
                          rel="noopener"
                        >
                          {' '}
                          Link{' '}
                        </a>{' '}
                        header to allow the user agent to start preloading resources while the server is still preparing
                        a response.
                      </i>
                    </span>
                  </li>
                </ul>
              </>
            }
          />
          <FAQItem
            title="2XX - Success"
            text={
              <>
                <p>
                  This class of status codes indicates the action requested by the client was received, understood and
                  accepted.
                </p>
                <ul>
                  <li>
                    <span>
                      <b>200</b> OK <i>The request has succeeded.</i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>201</b> Created{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>202</b> Accepted{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>203</b> Non-Authoritative Information
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>204</b> No Content{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>205</b> Reset Content{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>206</b> Partial Content{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>207</b> Multi-Status (WebDAV)
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>208</b> Already Reported (WebDAV)
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>226</b> IM Used{' '}
                    </span>
                  </li>
                </ul>
              </>
            }
          />
          <FAQItem
            title="3XX - Redirection"
            text={
              <>
                <p>
                  This class of status code indicates the client must take additional action to complete the request,
                  mostly used in URL redirection.
                </p>
                <ul>
                  <li>
                    <span>
                      <b>300</b> Multiple Choices{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>301</b> Moved Permanently{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>302</b> Found{' '}
                      <i>
                        (Previously "Moved temporarily"), some Web applications and frameworks use the 302 status code
                        as if it were the 303.
                      </i>{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>303</b> See Other
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>304</b> Not Modified{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>305</b> Use Proxy{' '}
                      <i>(deprecated due to security concerns regarding in-band configuration of a proxy)</i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>306</b> Switch Proxy{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>307</b> Temporary Redirect
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>308</b> Permanent Redirect
                    </span>
                  </li>
                </ul>
              </>
            }
          />
          <FAQItem
            title="4XX - Client errors"
            text={
              <>
                <p>
                  This class of status code is intended for situations in which the error seems to have been caused by
                  the client.
                </p>
                <ul>
                  <li>
                    <span>
                      <b>400</b> Bad Request{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>401</b> Unauthorized
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>402</b> Payment Required
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>403</b> Forbidden{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>404</b> Not Found
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>405</b> Method Not Allowed{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>406</b> Not Acceptable{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>407</b> Proxy Authentication Required{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>408</b> Request Timeout{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>409</b> Conflict 410 Gone
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>411</b> Length Required{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>412</b> Precondition Failed{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>413</b> Payload Too Large{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>414</b> URI Too Long{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>415</b> Unsupported Media Type{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>416</b> Range Not Satisfiable{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>417</b> Expectation Failed{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>418</b> I'm a teapot.{' '}
                      <i>
                        This code was defined in 1998 as one of the traditional IETF April Fools' jokes. This HTTP
                        status is used as an Easter egg on some websites, including Google.com.
                      </i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>421</b> Misdirected Request{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>422</b> Unprocessable Entity (WebDAV)
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>423</b> Locked (WebDAV)
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>424</b> Failed Dependency (WebDAV)
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>425</b> Too Early{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>426</b> Upgrade Required{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>428</b> Precondition Required{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>429</b> Too Many Requests{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>431</b> Request Header Fields Too Large
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>451</b> Unavailable For Legal Reasons
                    </span>
                  </li>
                </ul>
              </>
            }
          />
          <FAQItem
            title="5XX - Server errors"
            text={
              <>
                <p>
                  The server failed to fulfill a request. Response status codes beginning with the digit "5" indicate
                  cases in which the server is aware that it has encountered an error or is otherwise incapable of per
                </p>
                <ul>
                  <li>
                    <span>
                      <b>500</b> Internal Server Error{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>501</b> Not Implemented{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>502</b> Bad Gateway{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>503</b> Service Unavailable{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>504</b> Gateway Timeout{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>505</b> HTTP Version Not Supported
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>506</b> Variant Also Negotiates{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>507</b> Insufficient Storage (WebDAV)
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>508</b> Loop Detected (WebDAV)
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>510</b> Not Extended{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>511</b> Network Authentication Required
                    </span>
                  </li>
                </ul>
              </>
            }
          />
          <div className="blockQuote-7a0db092">
            <blockquote>
              Status code 0 is a response object that generally means no response, and can occur for various reasons,
              like request time out. Status 0 is not a valid HTTP status code.
            </blockquote>
            <blockquote>
              Status code 999 - a non-standard code is returned by some sites (e.g. LinkedIn) which do not permit
              scanning.
            </blockquote>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default HttpStatusCodes;
