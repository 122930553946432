import React, {FC, useState} from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import {BrokenLinkCorrectorProps} from '../../types';
import './styles.scss';

const FAQItem: FC<BrokenLinkCorrectorProps> = ({title, text}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="mb-0">
          <button
            className={classNames('faq_btn faq_btn_link', {
              collaped: !isOpen,
            })}
            onClick={handleOpen}
          >
            {title}
            <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} className="icon" />
          </button>
        </h5>
      </div>
      {isOpen && <div className="card-body">{text}</div>}
    </div>
  );
};

export default FAQItem;
